<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">智能电话机器人</div>
          <div class="desc">基于NLP、ASR、TTS等人工智能技术，精准识别用户意图，提供全天候的智能化语音服务，提升接待效率及人工分流率。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">预约演示</div>
        </div>
        <div class="right bg-right"></div>
      </div>
    </div>

    <!-- 产品对比 -->
    <div class="section bg-white vs">
      <div class="title">产品对比</div>
      <div class="bg"></div>
    </div>

    <!-- 产品功能 -->
    <div class="section bg-white func">
      <div class="title">产品功能</div>
      <div class="content">
        <div class="list">
          <div class="item">
            <img src="@/assets/phone-robot/img-fun-1.png" style="width: 506px;height: 550px" />
            <div class="text-info" style="width: 428px;">
              <div class="title">全天候智能语音导航服务</div>
              <div class="desc">智能电话机器人能够全天候提供服务，确保客户在任何时间都能得到及时的响应和帮助。为客户带来更优质、更满意的体验。</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info" style="width: 508px;">
              <div class="title">多轮对话能力</div>
              <div class="desc">智能电话机器人支持多轮对话，能够根据业务场景和客户意图进行多节点跳转，使对话更加灵活和深入。可以更好地理解客户的复杂需求，并提供更精准的解答和服务。</div>
            </div>
            <img src="@/assets/phone-robot/img-fun-2.png" style="width: 493px;height: 433px" />
          </div>
          <div class="item">
            <img src="@/assets/phone-robot/img-fun-3.png" style="width: 748px;height: 413px" />
            <div class="text-info" style="width: 428px;">
              <div class="title">可视化流程配置</div>
              <div class="desc">企业可根据需求自定义制作话术，满足多场景应用需求。具备触发意图、信息填槽、条件判断等，提供复杂业务话术制作能力，流程配置中可与客户的业务系统实现灵活对接。</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info" style="width: 533px;">
              <div class="title">可视化数据报表</div>
              <div class="desc">利用数据来发现趋势与异常，助力企业优化决策。通过可视化报表提高数据的可读性，加强数据交流。可辅助进行运营优化，有效提升对话机器人的自动化问答效率，准确捕获关键信息。</div>
            </div>
            <img src="@/assets/phone-robot/img-fun-4.png" style="width: 624px;height: 468px" />
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="section advantage">
      <div class="title">产品优势</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in advantageList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用场景 -->
    <div class="section bg-white scene">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in sceneList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="section case">
      <div class="title">客户案例</div>
      <div class="content">
        <div class="top-info">
          <div class="title">金融案例-某银行智能语音客服系统项目</div>
          <div class="content">某银行现有的电话导航系统完全依赖于IVR的业务场景，只提供简单的按键跳转功能；对话场景无配置能力，如有变更需要换版，无法支持客服人员对业务流程的灵活管理，无法支撑业务场景的快速拓展。</div>
        </div>
        <div class="detail-panel">
          <div class="left">
            <div class="title">语音客服机器人</div>
            <div class="list">
              <div class="item">
                <div class="top">95.32%</div>
                <div class="bottom">转写准确率</div>
              </div>
              <div class="item">
                <div class="top">95.58%</div>
                <div class="bottom">导航正确率</div>
              </div>
              <div class="item">
                <div class="top">90%</div>
                <div class="bottom">业务覆盖率</div>
              </div>
              <div class="item">
                <div class="top">30%</div>
                <div class="bottom">导航分流率</div>
              </div>
            </div>
            <div class="btn">项目价值</div>
          </div>
          <div class="right">
            <div class="list">
              <div class="item" v-for="item in prodValueList" :key="item.name">
                <div class="title">{{item.name}}</div>
                <div class="content" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advantageList: [
        {
          name: 'NLP意图识别',
          content: '采用语义和正则双模型识别技术，提升用户语音输入内容的准确性识别',
          icon: 'icon-adv-1.png'
        },
        {
          name: '可视化流程配置',
          content: '通过可视化界面配置，具备用户友好的操作界面，企业可以根据具体业务需求，拖拽式地对话术流程进行配置。',
          icon: 'icon-adv-2.png'
        },
        {
          name: '智能学习',
          content: '未匹配的知识根据问法支持聚类，并可一键学习知识库中，不断扩展和更新其知识库，提高客服机器人的知识匹配准确率',
          icon: 'icon-adv-3.png'
        },
        {
          name: '人机协同',
          content: '智能分流无缝转人工，人机协作，为用户提供恰到好处的服务',
          icon: 'icon-adv-4.png'
        }
      ],
      sceneList: [
        {
          name: '保险',
          content: '保单状态/财险寿险出险/投诉意见/保单查询/缴费进度查询/保单配送/ 分红查询',
          icon: 'icon-scene-1.png'
        },
        {
          name: '银行',
          content: '余额查询/银行卡激活/卡号查询/交易明细查询/银行卡挂失',
          icon: 'icon-scene-2.png'
        },
        {
          name: '政务服务',
          content: '个人社保查询/社保卡挂失/公积金查询/津贴咨询/政策查询/证明办理',
          icon: 'icon-scene-3.png'
        },
        {
          name: '能源行业',
          content: '上线营销/配网抢修/过户/报装/增容/费用查询/维修咨询/业务办理',
          icon: 'icon-scene-4.png'
        }
      ],
      prodValueList: [
        {
          name: '对话管理',
          content: `多轮对话<br/>复杂场景意图识别`,
        },
        {
          name: '数据运营',
          content: `数据可视化<br/>对话检索`,
        },
        {
          name: '语音交互',
          content: `实现多样式<br/>语音交互`,
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/phone-robot/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
